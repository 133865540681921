/* @flow */
/* eslint no-undef: 0 */

import * as React from 'react'
import { navigate } from 'gatsby'
import { pay } from '@wino/sdk'

import { PayCallbackTemplate } from '../templates'
import { getQueryVariable } from '../utils'

type Props = {
  location: {
    href: string,
    search: string,
  },
}

export default class PayTransaction extends React.Component<Props> {
  componentDidMount() {
    this.redirectToCheckout()
  }

  redirectToCheckout = async () => {
    const { location } = this.props
    const shopId = getQueryVariable(location, 'shop_id')
    const uuid = getQueryVariable(location, 'uuid')
    try {
      const payArguments = [
        void 0,
        {
          serviceRootUrl:
            window.location.hostname !== 'wino.fr'
              ? process.env.GATSBY_STAGGING_SERVICE_ROOT_URL
              : process.env.GATSBY_SERVICE_ROOT_URL,
        },
      ]
      const response = await pay(...payArguments).checkout.find(uuid, shopId)
      const { stripe_session: sessionId } = response
      // $FlowFixMe
      const stripe =
        Stripe &&
        Stripe(
          window.location.hostname !== 'wino.fr'
            ? process.env.GATSBY_STAGGING_STRIPE_API_KEY
            : process.env.GATSBY_STRIPE_API_KEY,
        )
      const { error } = await stripe.redirectToCheckout({ sessionId })
      if (error) {
        throw new Error(error)
      }
    } catch (err) {
      navigate('/error?message=Impossible de récupérer votre panier')
      throw err
    }
  }

  render() {
    const { location } = this.props
    const shopLogo = getQueryVariable(location, 'shop_logo')
    return (
      <PayCallbackTemplate
        url={location.href}
        name="Wino"
        logo={shopLogo}
        title="Chargement en cours"
        description="Veuillez patienter quelques secondes, nous récupérons votre panier ..."
        brandText="La caisse enregistreuse de votre commerçant*"
        brandInformations="*Wino est le service choisi par votre commerçant pour les procédures de paiement en ligne de vos achats.  La plateforme garantit des paiements entièrement sécurisés."
      />
    )
  }
}
